/* flexbox*/
.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  padding: 0;
}
.slick-slider,
.slick-slider *,
.wrapper *,
.box * {
  box-sizing: border-box;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

td,
th {
  border-bottom: unset;
}

body {
  margin: 0px;
  overflow-x: clip; /* to fix .u-full-width overflow */
}

/* Styled Components v5 don't support @container queries. */
/* TODO: Move this and remove selectors once we update it. Extra selectors were added for overriding. */
@container (min-aspect-ratio: 16/9) {
  .nva-slidercard .nva-slidercard__bg-video-wrapper .nva-slidercard__video iframe {
    height: calc(56.25cqw + 200px);
    width: calc(100% + 200px);
  }
}
@container (min-aspect-ratio: 16/9) and (min-aspect-ratio: 17/8) {
  .nva-slidercard .nva-slidercard__bg-video-wrapper .nva-slidercard__video iframe {
    width: 100%
  }
}
@container (max-aspect-ratio: 16/9) {
  .nva-slidercard .nva-slidercard__bg-video-wrapper .nva-slidercard__video iframe {
    width: calc(177.78cqh + 300px);
    height: calc(100% + 200px);
  }
}


.contentful-inspector--active [data-contentful-field-id][data-contentful-entry-id]:not(hover) {
  outline: none!important;
  transition: none!important;
}

.contentful-inspector--active .contentful-tooltip {
  opacity: 0;
}

.contentful-inspector--active .contentful-tooltip:hover,
:has(.contentful-inspector--active [data-contentful-field-id][data-contentful-entry-id]:hover) .contentful-tooltip {
  opacity: 1;
}
